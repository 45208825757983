@import '../../../../App';


.chat-main-private {
  padding-top: 15px;
  height: calc(90% - #{$wight-title-layout-children});
}

.chat-main-public {
  height: calc(90% - #{$wight-title-layout-children} - 48px );
}
.chat-main-public-hotline{
  height: calc(90% - #{$wight-title-layout-children});
  padding-top: 15px;
  position: relative;
  .chat-main-public-hotline-helper-mobile{
    right: 5px;
    top: 5px;
    position: absolute;
    @media (min-width: mediaWight(mobileXl)) {
      display: none;
    }
  }

}
.chat-main-public-hotline,
.chat-main-public,
.chat-main-private {
  overflow-y: scroll;
  box-sizing: border-box;
  padding-top: 15px;
}

.chat-header {
  z-index: 2;
  position: relative;
  top: 0;
  height: 48px;
  padding: 14px;
  background-color: $color-background;
  @include flex(none, center);
  gap: 20px;
  .user-name-near-avatar {
    @include flex(none, center);

  }
  .user-detail-chat {
    @include flex(space-between, none, column);
    padding-top: 5px;
    .userEmail{
      text-align: start;
    }
    @media (max-width: 1400px) {
      font-size: 13px;
      .userEmail{
        font-size: 12px;
      }
    }
  }

  .user-status {
    width: 64px;
    margin-left: 8px;
    height: 20px;
    border-radius: 12px;
    font-size: 12px;
    //color: #149057;
    @include flex(none, center);
    gap: 6px;
    padding-top: 2px;
    box-sizing: border-box;

    .status-circle {
      width: 7px;
      height: 7px;
      margin-left: 5px;
      margin-bottom: 2px;
      border-radius: 3.5px;
    }
  }

  @media (max-width: 1400px) {
    gap: 10px;
  }
  @media (min-width: 1401px) {
    flex-shrink: 0;
  }

}

.send-msg {
  @include flex(space-between,center);
  gap: 5px;
  background: $bgColor-input;
  position: relative;
  border-radius: 10px;
  margin: 0 10px;
  padding: 16px 16px 16px 12px;
}

.send-msg-input-flex-center {
  @include flex(none, center);
  position: relative;
}

.delete-file-chat-icon {
  position: relative;
  bottom: 20px;
}

.file-chose-chat-icon:hover,
.delete-file-chat-icon:hover {
  cursor: pointer;
}

.form-chat {
  height: 10%;
  width: 100%;
  position: relative;
  background: $bgColor-form;
}
@media (max-width: mediaWight(desktopSm)) {

  .chat-main-private{
    height: 50vh;
    .msg-div{
      gap: 0;

      .not-my-msg{
        margin-left: 50px;
      }

    }
  }
  .chat-main-public{
    height: calc(90% - 87px );
    //margin-bottom: 60px;
    //height: 50vh;
    .msg-div{
      gap: 0;

      .not-my-msg{
        margin-left: 50px;
      }

    }
  }
  .chat-main-public-hotline{
    height: 76vh;
  }
}

@media (min-width:  mediaWight(mobileXl)) and (max-width:  mediaWight(desktopSm)) {
  .form-chat {
    width: 100%;
    //bottom: 63px;

  }
}

@media (max-width: mediaWight(mobileXl)) {
  .form-chat {
    width: 100%;
    //bottom: 63px;
  }
  .chat-main-private{
    height: 50vh;
  }
}

