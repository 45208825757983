@import "../../../App";

.news-details-wrapper {
  background: $bgColor-event;
  height: 100%;
  width: 100%;

  .news-header {
    background: $color-background;
    min-height: 20%;
    display: flex;
    text-align: left;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    padding-left: 80px;

    .news-divider {
      width: 100px;
      height: 1px;
      background: $bgColor-poster-clinic;
    }

    h3 {
      @include font-style(Helvetica Neue, 700, 24px, 30px);

      text-align: start;
    }

    .poster-detail-date {
      @include font-style($font-third, 400, 14px, 25px);
      margin-top: -10px;
    }

    p {
      @include font-style(Helvetica Neue, 300, 16px, 20px);

      span {
        color: rgba(40, 40, 40, 0.5);
        margin-left: 10px;
      }
    }

    @media screen and (max-width: 1468px) {
      padding: 10px;
    }
  }

  .news-layout {
    background: $bgColor-event;
    display: flex;
    gap: 30px;

    .responsive-iframe {
      width: 100%;
      min-height: fit-content;
      height: 400px;
      min-width: 100%;

      @media screen and (max-width: 1468px) {
        height: 300px;
        min-width: fit-content;
        width: 100%;
      }

      @media screen and (max-width: 768px) {
        height: 200px;
        min-width: fit-content;
        width: 100%;
      }
    }

    h4 {
      @include font-style(Helvetica Neue, 500, 20px, 30px);
    }

    p {
      @include font-style(Helvetica Neue, 300, 16px, 24px);
    }

    * {
      flex: 1;
    }

    &:last-child {
      padding: 0px 10px;
    }

    @media screen and (max-width: 1468px) {
      flex-direction: column;
      padding: 10px;
    }
  }
}
