@import "../../../../../../App";
.msg-div {
  display: flex;
  flex-direction: column;
  margin: 0 5px;
  padding-top: 5px;

  .msg {
    @include hyphens;
    width: fit-content;
    position: relative;
    max-width: 60%;
  }

  .my-msg {
    justify-content: right;
    background: $bgColor-my-msg;
    border-radius: 12px 2px 12px 12px;
    margin: 0 29px 24px 24px;
    color: $color-my-msg;
  }

  .not-my-msg {
    background: $bgColor-not-my-msg;
    margin: 0 24px 24px 49px;
    border-radius: 2px 12px 12px 12px;
    color: $color-not-my-msg;
  }
  .admin-msg{
    background: $bgColor-admin-msg;
    color: $color-admin-msg;
  }

  h4 {
    color: $color-name-chats;
    align-self: flex-end;
  }

  .msg-time {
    display: flex;
    gap: 7px;
    position: relative;

    .chat-header-avatar{
      position: relative;
      bottom: -25px;

      img {
        height: 48px;
        position: relative;
        width: 48px;
      }
      .avatar-status {

        height: 40px;
        width: 40px;
        //img {
        //  height: 100%;
        //}
        .offline-circle,
        .online-circle{
          width: 8px;
          height: 8px;
        }
      }
    }
    .chat-header-avatar-edit{
      position: relative;
      bottom: 0px;
      right: 10px;
    }
    p {
      color: $color-time-chats;
      align-self: flex-end;

    }

    margin-bottom: 5px;
  }
}