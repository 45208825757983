@import '../../../App';

.private-chat-main {
    min-height: 500px;
    max-height: 900px;
    overflow: scroll;
    @media (max-width: mediaWight(desktopSm)) {
        min-height: 68vh;

    }
}
.private-chat-card{
    display: flex;
    gap: 10px;
    padding: 10px;
    &:hover{
        cursor: pointer;
    }
}

.private-chat-detail {
    display: flex;
    width: 100%;
    flex-direction: column;
}
.private-chat-name{
    @include flex(space-between,center);
    .name{ @include font-style($font-title, 400, 16px, 18px) }
    .time{
        @include font-style($font-main, 400, 12px, 14px);
        color: $time-color;
    }
}
.private-chat-msg{
    @include flex(space-between,center);
    .msg{ @include font-style($font-main, 400, 14px, 16px) }
    .msg-numbers{
        background-color: $bgColor-new-msg;
        color: $color-new-msg;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 999px;
        width: 25px;
        height: 25px;
        font-size: 12px;
        padding-left: 1px;
    }
}