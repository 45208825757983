@import "../../../App";

.upcoming-events-head .title {
  @include title();
  @include font-style($font-second, 800, 48px, 58.01px);
}

.upcoming-events-main {
  width: 72%;
  margin: auto;
}

.upcoming-events-head {
  @include flex(space-between, center);
  padding-bottom: 46px;
}

.upcoming-events-head-option {
  display: flex;
  gap: 10px;
}

.upcoming-events-cards-main {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 48px;
}

.upcoming-events-card {
  background-color: $bgColor-event;
  width: 32.1%;
  height: 580px;
  margin-bottom: 24px;

  img {
    object-fit: cover;
  }
}

.upcoming-events-card-img {
  width: 100%;
  height: 245px;
}

.upcoming-events-card:hover {
  transform: scale(1.02);
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -ms-transform: scale(1.02);
  -o-transform: scale(1.02);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.upcoming-event-card-detail {
  padding: 20px 16px 36px 16px;
  width: 90%;
  margin: auto;
  display: flex;
  gap: 16px;

  .month {
    @include font-style($font-second, 500, 24px, 28px);
    color: $color-events-month;
    text-align: center;

  }

  .day {
    @include font-style($font-second, 700, 24px, 28px);
    color: $color-events-day-line;
    padding-top: 8px;
    text-align: center;

  }

  .day-line {
    background: $color-events-day;
    width: 3px;
    margin: auto;
    height: 50px;
    display: block;
  }

  .time {
    @include font-style($font-main, 400, 18px, 20px);
    color: $color-events-time;
    padding-top: 16px;
  }

  .event-heading {
    @include font-style($font-second, 700, 24px, 32px);
    color: $event-event-heading;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .event-desc {
    @include font-style($font-main, 400, 16px, 28px);
    @include para-color;
    margin-top: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

.upcoming-event-card-detail div {
  display: flex;
  flex-direction: column;

}

.upcoming-events-tags {
  @include flex(start, center);
  gap: 24px;

  .tag {
    @include font-style($font-main, 500, 16px, 16px);
    @include tag-color();
    background-color: $bgColor-events-tags;
    @include flex(center, center);
    gap: 12px;
    padding: 19px 24px;
    border-radius: 9999px;

    .upcoming-events-tags-svg {
      fill: $color-events-tags-svg;
    }

    .upcoming-events-tags-svg:hover {
      cursor: pointer;
      fill: $color-events-tags-svg-hover;
    }
  }
}

.date-pick {
  padding: 7px;
  border: 1px solid lightgray;
  border-radius: 3px;
}

.upcoming-event-speakers {
  display: flex !important;
  flex-direction: row !important;
  margin-top: 8px;
}

.upcoming-event-speakers .speaker-dp {
  border-radius: 50px;
}

.upcoming-event-speakers .speakers-overlay-main:not(:first-child) {
  margin-left: -14px;
}

.upcoming-event-speakers .speaker-dp:hover {
  box-shadow: 1px 1px 10px $color-speaker-dp-hover;
}

//.css-b62m3t-container {
//  @include font-style(HelveticaNeueCyr, 400, 14px, 20px);
//
//  .css-1s2u09g-control {
//    color: $whiteColor;
//  }
//}

.date-pick {
  @include font-style($font-main, 400, 14px, 20px);
}

@media (min-width: 1024px) and (max-width: 1767px) {
  .upcoming-events-main {
    width: 82%;
  }

  .upcoming-events-card {
    width: 31.75%;
    height: 520px;
  }

  .upcoming-events-card-img {
    height: 200px;
  }

  .upcoming-event-card-detail {
    padding: 20px 16px 36px 16px;
    gap: 12px;

    .month {
      @include font-style($font-second, 500, 20px, 28px);
    }

    .day {
      @include font-style($font-second, 700, 20px, 28px);
    }

    .day-line {
      background: $color-events-day-line;
      width: 3px;
      margin: auto;
      height: 50px;
      display: block;
    }

    .time {
      @include font-style($font-main, 400, 16px, 20px);
    }

    .event-heading {
      @include font-style($font-second, 700, 20px, 28px);
    }

    .event-desc {
      @include font-style($font-main, 400, 14px, 28px);
    }
  }
}

.upcoming-event-load-more {
  text-align: center;
  align-items: center;
  margin-top: 48px;
}

.upcoming-event-load-more button {
  cursor: pointer;
  border: none;
  background: $bgColor-event-load-more-button;
  display: block;
  width: max-content;
  height: auto;
  color: $color-white;
  margin: 0 auto;
  border-radius: 9999px;
  padding: 12px 36px;
  transition: all 0.4s ease-in-out;
  @include font-style($font-main, 500, 14px, 28px);
}

.upcoming-event-load-more button:hover {
  transform: scale(1.05);
}


//Tablets Responsive

@media (min-width: 768px) and (max-width: 1023px) {

  .upcoming-events-head {
    .title {
      @include font-style($font-second, 800, 44px, 58px);
    }
  }

  .upcoming-events-main {
    width: 85%;
  }

  .upcoming-events-cards-main {
    gap: 2%;
  }

  .upcoming-events-card {
    width: 49%;
    height: 520px;
  }

  .upcoming-events-card-img {
    height: 190px;
  }
}

//Mobile Reponsive

@media (max-width: 767px) {

  .upcoming-events-cards-main {
    margin: 24px auto;
  }

  .upcoming-events-card {
    width: 100%;
    height: 520px;
  }

  .upcoming-events-card-img {
    height: 190px;
  }

  .upcoming-event-load-more {
    margin-top: 24px;
  }

  .upcoming-event-load-more p {
    width: 100%;
    border-radius: 6px;
    padding: 10px 0;
  }

  .upcoming-events-head {
    flex-direction: column;
    gap: 24px;
    padding-bottom: 24px;

    .title {
      @include font-style($font-second, 800, 40px, 58px);
    }
  }

  .upcoming-events-main {
    width: 90%;
    margin: 0 auto;
  }

  .upcoming-events-head-option {
    width: 100%;
    justify-content: center;
  }

  //.css-b62m3t-container {
  //  width: 100%;
  //}

  //.css-1s2u09g-control {
  //  padding: 4px 0;
  //}

  .upcoming-events-tags {
    @include flex(start, center);
    overflow-y: scroll;
    scrollbar-width: none;
    gap: 16px;

    .tag {
      @include font-style($font-main, 500, 14px, 16px);
      padding: 12px 20px;

      span {
        width: max-content;
      }
    }
  }

  .upcoming-events-tags::-webkit-scrollbar {
    display: none;
  }

}